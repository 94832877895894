/**
 * CMO Form Validate
 * author: Christian Montenegro
 * version: 1.0.1
 */

const cmoFormValidate = (function () {
  const fields = [];
  let options;
  const errorCounter = 0;

  const validations = {
    isNotEmpty(val) {
      return val.trim() !== '';
    },

    isName(val) {
      const nameRegex = /^[a-zA-Záéíóúñ,. ]+$/;
      return nameRegex.test(val);
    },

    isEmail(val) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(val);
    },

    isInteger(val) {
      return parseInt(val) === Number(val);
    },

    isDNI(val) {
      return parseInt(val) === Number(val) && val.trim().length === 8;
    },
  };

  const rules = {
    names: validations.isName,
    email: validations.isEmail,
    numbers: validations.isInteger,
    required: validations.isNotEmpty,
    dni: validations.isDNI,
  };

  var validate = {

    init(data) {
      options = data;

      if ((typeof options) === 'string') {
        const selector = options;
        var form = document.querySelector(selector);
        var button = form.querySelector('[data-action]');
        const inputs = form.querySelectorAll('[data-rule]');

        var fieldsArr = Array.prototype.map.call(inputs, input => ({ el: input, rule: input.dataset.rule, errorMessage: input.dataset.errorMessage }));
      } else {
        var form = document.querySelector(options.form);
        const fields = options.fields;
        var button = form.querySelector(options.button);

        var fieldsArr = options.fields.map(fieldObj => ({ el: form.querySelector(fieldObj.selector), rule: fieldObj.rule, errorMessage: fieldObj.message }));
      }

      validate._blurHandle(fieldsArr);

      if (button) {
        validate._clickHandle(fieldsArr, button); // for specific button
      } else {
        validate._submitHandle(form, fieldsArr); // for submit event
      }
    },

    isValid(selector) {
      const el = document.querySelectorAll(selector)[0];

      return (el.querySelectorAll('.field-error').length === 0);
    },

    /**
         * Validation when file is blur
         * @param  {[Array]} Array of Objects
         */
    _blurHandle(fieldsArr) {
      const self = this;

      fieldsArr.forEach((fieldObj) => {
        fieldObj.el.addEventListener('blur', () => {
          self._errorHandle(fieldObj.el, fieldObj.rule, fieldObj.errorMessage);
        });
      });
    },

    /**
         * Validation by button click, validation without submit
         * @param  {[Array]} Array of Objects
         * @param  {[el]} button el
         */
    _clickHandle(fieldsArr, button) {
      const self = this;

      button.addEventListener('click', (evt) => {
        fieldsArr.forEach((fieldObj) => {
          self._errorHandle(fieldObj.el, fieldObj.rule, fieldObj.errorMessage);
        });
      });
    },

    /**
         * Validation when form is submited
         * @param  {string} form   is the selector of the form
         * @param  {array} fields is an array with the field selector, type of validation and error message
         */
    _submitHandle(form, fieldsArr) {
      const self = this;

      form.addEventListener('submit', (evt) => {
        evt.preventDefault();

        fields.forEach((fieldObj) => {
          self._errorHandle(fieldObj.el, fieldObj.rule, fieldObj.errorMessage);
        });
      });
    },

    _errorHandle(field, rule, message) {
      const currentClass = field.className;

      const errorMsg = document.createElement('small');
      errorMsg.className = 'error-message';

      const parentNode = field.parentNode;

      if (rules[rule](field.value)) {
        field.className = currentClass.replace('field-error', '');
        const currentErrorMsg = parentNode.querySelector('small');
        if (currentErrorMsg) {
          parentNode.removeChild(parentNode.querySelector('small'));
          field.dataset.tooltip = '';
        }
      } else if (currentClass.indexOf('field-error') === -1) {
        field.className = `${currentClass} field-error`;
        field.parentNode.appendChild(errorMsg);
        errorMsg.innerHTML = message;
        field.dataset.tooltip = message;
      }
    },
  };

  return {
    init: validate.init,
    isValid: validate.isValid,
  };
}());

module.exports = cmoFormValidate;
