const gmaps = () => {
  const mapCanvas = document.getElementById('map');

  if (mapCanvas === null) {
    return;
  }

  let map;
  const coordinates = JSON.parse(mapCanvas.dataset.coordinates);
  const address = mapCanvas.dataset.address;
  const myLatlng = JSON.parse(mapCanvas.dataset.center);

  map = new google.maps.Map(document.getElementById('map'), {
    center: myLatlng,
    zoom: 15,
  });

  const infowindow = new google.maps.InfoWindow({
    content: address,
  });

  const marker = new google.maps.Marker({
    position: coordinates,
    map,
    title: address,
  });

  infowindow.open(map, marker);
};

module.exports = gmaps;
