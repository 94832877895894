window.$ = require('jquery');
const hashNav = require('../vendors/cmoHashNavigation.js');

const nav = function () {
  const navHamburger = document.querySelector('.nav__hamburger');
  const navMenu = document.querySelector('.nav__menu');

  if (!navMenu) {
    return;
  }

  hashNav('.nav__menu');

  let navClass;

  navHamburger.addEventListener('click', () => {
    navMenu.className = (navMenu.className.indexOf('active') === -1) ? `${navMenu.className  } active` : navMenu.className.replace(' active', '');
  });
};

module.exports = nav;
