import nav from './components/nav';
import gmaps from './components/gmaps';
import form from './components/form';

document.addEventListener('DOMContentLoaded', () => {
  nav();
  gmaps();
  form();
});

