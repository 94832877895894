import cmoFormValidate from '../vendors/cmoFormValidate';

const formComponent = () => {
  const form = document.querySelector('.catering__form form');

  if (!form) {
    return;
  }

  cmoFormValidate.init('.catering__form form');

  const button = document.querySelector('[data-action]');

  button.addEventListener('click', (event) => {
    event.preventDefault();

    if (cmoFormValidate.isValid('.catering__form form') && window.grecaptcha.getResponse().length > 0) {
      button.disabled = 'true';
      $(button).addClass('disabled');

      const data = $('.catering__form form').serialize();
      $.ajax({
        method: 'POST',
        data,
        url: '/send.php',
      }).done((response) => {
        button.disabled = 'false';
        $(button).removeClass('disabled');
        $('.catering__form').addClass('thanks');
        form.reset();
      });
    }
  });
};

module.exports = formComponent;
